export const detectClickOutside = (node: Element) => {
	const handleClick = (event: MouseEvent) => {
		if (
			node &&
			event.target instanceof Node &&
			!node.contains(event.target) &&
			!event.defaultPrevented
		) {
			const evt = new CustomEvent('click_outside', { detail: node });
			node.dispatchEvent(evt);
		}
	};

	document.addEventListener('click', handleClick, true);

	return {
		destroy() {
			document.removeEventListener('click', handleClick, true);
		}
	};
};
